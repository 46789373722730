import { AsyncPipe, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, DestroyRef, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { EneLayoutService } from "@enersis/ui-helper";
import { LetDirective } from "@ngrx/component";
import { Store, select } from "@ngrx/store";
import { Observable, filter, first } from "rxjs";
import { CardModule } from "src/app/common/components/card/card.module";
import { LeadComponent } from "src/app/common/components/dialogs/lead/lead.component";
import { LeadModule } from "src/app/common/components/dialogs/lead/lead.module";
import { SegmentedButtonComponent } from "src/app/common/components/segmented-button/segmented-button.component";
import { Dialog } from "src/app/common/enums/dialogs";
import { PLUGIN } from "src/app/common/enums/plugins";
import { SLOT } from "src/app/common/enums/slot";
import { IN_ANIMATION } from "src/app/core/animations/in-out-animation";
import { CRM_MODULES_IDS_MAP } from "src/app/core/constants/modules-crm-ids-map";
import { ContentfulContentType, OrderFormEntry, SalesInfoEntry } from "src/app/core/interfaces/contentful";
import { AuthService } from "src/app/core/services/auth.service";
import { DialogsService } from "src/app/core/services/dialogs.service";
import { QueryParamsManagerService } from "src/app/core/services/query-params-manager.service";
import { takeLatestFrom } from "src/app/core/utils/ngrx";
import { loadLaunchpad } from "src/app/ngrx/actions/admin-data.actions";
import { openDialog } from "src/app/ngrx/actions/dialogs.actions";
import { openNews } from "src/app/ngrx/actions/overlay.actions";
import { navigateToPlugin, navigateToSlot } from "src/app/ngrx/actions/router.actions";
import { RootState } from "src/app/ngrx/root-reducers";
import { selectRegionAgs, selectRegionId } from "src/app/ngrx/selectors/app.selectors";
import { NewsActionsManagerService } from "../../news/news-actions-manager.service";
import { BannersCarouselComponent } from "../components/banners/banners-carousel/banners-carousel.component";
import { MarketplacePopupDialogComponent } from "../components/dialogs/marketplace-popup-dialog/marketplace-popup-dialog.component";
import { StoryTellerDialogComponent } from "../components/dialogs/story-teller-dialog/storyteller-dialog.component";
import { TilesGridComponent } from "../components/tiles-grid/tiles-grid.component";
import { LaunchpadAnalyticsService } from "../launchpad-analytics.service";
import { LaunchpadComponentStore } from "./launchpad.component-store";
import { LAUNCHPAD_MEDIUM_WIDTH, LAUNCHPAD_SMALL_WIDTH, SEGMENTS } from "./launchpad.constants";
import {
  LaunchpadMarketplaceBannerProps,
  LaunchpadOverlay,
  LaunchpadTileConfig,
  LaunchpadViewModel
} from "./launchpad.interfaces";
import { convertToLegacyOverlay } from "./launchpad.utils";

function getSlotOrPluginFromModuleId(moduleId: string): SLOT | PLUGIN {
  return CRM_MODULES_IDS_MAP[moduleId];
}

@Component({
  selector: "tpm-launchpad",
  templateUrl: "./launchpad.component.html",
  styleUrls: ["./launchpad.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LaunchpadComponentStore, LaunchpadAnalyticsService],
  imports: [
    LetDirective,
    NgIf,
    AsyncPipe,
    SegmentedButtonComponent,
    CardModule,
    LeadModule,
    TilesGridComponent,
    BannersCarouselComponent
  ],
  animations: [IN_ANIMATION]
})
export class LaunchpadComponent {
  public readonly segments = SEGMENTS;
  public readonly vm$!: Observable<LaunchpadViewModel>;
  public readonly isMobile$!: Observable<boolean>;
  public readonly isTileSmall$!: Observable<boolean>;
  public readonly isMarketplaceBannerMedium$!: Observable<boolean>;

  private readonly store = inject(Store<RootState>);
  private readonly launchpadService = inject(LaunchpadComponentStore);
  private readonly dialogsService = inject(DialogsService);
  private readonly layoutService = inject(EneLayoutService);
  private readonly queryParamsManagerService = inject(QueryParamsManagerService);
  private readonly newsActionsManagerService = inject(NewsActionsManagerService);
  private readonly analytics = inject(LaunchpadAnalyticsService);
  private readonly authService = inject(AuthService);

  private readonly destroyRef = inject(DestroyRef);

  constructor() {
    this.isMobile$ = this.layoutService.isMobileScreen$;
    this.isTileSmall$ = this.layoutService.isBreakpointMatched$(LAUNCHPAD_SMALL_WIDTH);
    this.isMarketplaceBannerMedium$ = this.layoutService.isBreakpointMatched$(LAUNCHPAD_MEDIUM_WIDTH);
    this.vm$ = this.launchpadService.vm$;
  }

  ngOnInit() {
    this.store.pipe(select(selectRegionId)).subscribe((regionId) => {
      this.launchpadService.fetchMarketplaceBanner(regionId);
      this.launchpadService.fetchSalesInfos(regionId);
      this.launchpadService.fetchOrderForms(regionId);
    });
  }

  public changeOverlay(overlay: LaunchpadOverlay): void {
    if (overlay === "marketplace") {
      this.dialogsService
        .open(MarketplacePopupDialogComponent, {
          autoFocus: false,
          panelClass: "marketplace-popup-dialog",
          width: "450px"
        })
        .afterClosed()
        .pipe(filter(Boolean), takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.launchpadService.changeOverlay(overlay);
        });
    } else {
      this.launchpadService.changeOverlay(overlay);
    }
  }

  public onTileClick(config: LaunchpadTileConfig): void {
    const slotOrPlugin = getSlotOrPluginFromModuleId(config.name);

    switch (config.overlay) {
      case "regulated": {
        this.store.dispatch(navigateToSlot({ slot: slotOrPlugin as SLOT }));
        break;
      }
      case "marketplace": {
        switch (config.source.type) {
          case "modal": // intentional fallthrough
          case "iframe":
            this.store.dispatch(navigateToPlugin({ id: slotOrPlugin as PLUGIN }));
            break;
          case "target":
          default:
            window.open(config.source.url, "target");
            break;
        }
        break;
      }
    }

    this.analytics.trackTileClick(config.name);
  }

  public onLeadClick(config: LaunchpadTileConfig): void {
    if (config.overlay === "marketplace") {
      if (config.orderForm) {
        this.openOrderModuleDialog(config.name, config.orderForm, config.overlay);
      } else if (config.salesInfo) {
        this.openLeadDialog(config.name, config.salesInfo);
      }

      this.analytics.trackLeadButtonClick(config.name);
    }
  }

  public onProductManagementClick(): void {
    this.dialogsService.openProductManagementDialog();
  }

  public onStoryTellerClick(config: LaunchpadTileConfig, overlay: LaunchpadOverlay): void {
    this.dialogsService.open(StoryTellerDialogComponent, {
      panelClass: "story-teller-dialog",
      autoFocus: false,
      width: "530px",
      data: {
        overlay,
        title: config.title,
        ...(config.storyTeller || {})
      }
    });
    this.analytics.trackStoryTellerClick(config.name);
  }

  public onNewsCarouselBannerClick(config: any, overlay: LaunchpadOverlay): void {
    this.queryParamsManagerService.openNews(convertToLegacyOverlay(overlay), config.contentType, config.id);
    this.analytics.trackNewsCarouselSlideClick(config.headline, overlay);
  }

  public onMarketplaceBannerClick(props: LaunchpadMarketplaceBannerProps, overlay: LaunchpadOverlay): void {
    this.launchpadService.getPopulatedSalesInfosAndOrderForms$.pipe(first()).subscribe(([salesInfos, orderForms]) => {
      if (props.linkUrl) {
        this.analytics.trackMarketCarouselSlideClick(props.teaserInfo);
        window.open(props.linkUrl, "target");
      } else if (props.moduleId) {
        if (!CRM_MODULES_IDS_MAP[props.moduleId]) {
          throw new Error("Wrong module-id of banner");
        }
        const orderForm = orderForms?.find(
          (orderForm) => this.launchpadService.getOrderFormModuleId(orderForm) === props.moduleId
        );
        const salesInfo = salesInfos?.find(
          (salesInfo) => this.launchpadService.getSalesInfoModuleId(salesInfo) === props.moduleId
        );
        if (orderForm) {
          this.openOrderModuleDialog(props.moduleId, orderForm, overlay);
        } else if (salesInfo) {
          this.openLeadDialog(props.moduleId, salesInfo);
        } else {
          console.warn(`clicked banner of ${props.moduleId}, but no associated link or order-form or sales-info found`);
        }
        this.analytics.trackBannerCarouselSlideClick(props.moduleId as `module-${number}`);
      }
    });
  }

  public onNewsTileCardClick(event): void {
    this.newsActionsManagerService.dispatch(event.action, event.url, event.entry);
  }

  public onNewsClicked(overlay: LaunchpadOverlay): void {
    const contentType =
      overlay === "regulated"
        ? ContentfulContentType.NBRegulatedModulesNews
        : ContentfulContentType.NBServicesModulesNews;
    this.analytics.trackNewsLinkCardClick();
    this.store.dispatch(
      openNews({
        contentType
      })
    );
    this.queryParamsManagerService.removeQueryParams();
  }

  public onSubscriptionClicked(): void {
    this.analytics.trackSubscriptionLinkCardClick();
    this.store.dispatch(openDialog({ dialogType: Dialog.NOTIFICATION_SETTINGS }));
  }

  private openLeadDialog(moduleId: string, salesInfo: SalesInfoEntry): void {
    this.dialogsService.open(LeadComponent, {
      data: { slot: CRM_MODULES_IDS_MAP[moduleId], salesInfo },
      autoFocus: false,
      panelClass: "lead-dialog",
      width: "1000px"
    });
  }

  private openOrderModuleDialog(moduleId: string, orderForm: OrderFormEntry, overlay: LaunchpadOverlay): void {
    this.dialogsService
      .openOrderModuleDialog(moduleId, orderForm)
      .afterClosed()
      .pipe(filter(Boolean), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.authService.refreshUserInfo();
        this.store.dispatch(loadLaunchpad({ listType: overlay, ags: takeLatestFrom(this.store, selectRegionAgs) }));
      });
  }
}
