import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { GpiIcon } from "@enersis/gp-components/libs/iconfont/dist/gpi-iconfont";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-dialog-frame",
  templateUrl: "./dialog-frame.component.html",
  styleUrls: ["./dialog-frame.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogFrameComponent {
  @Input() public icon: GpiIcon | string;
  @Input() public header: string;
  @Input() public closable: boolean = true;

  @Input() public width: string = "100%";

  @Input() public hasSticky: boolean = false;
  @Input() public hasCloseButton: boolean = true;
  @Input() public submitDisabled: boolean = false;

  @Input() public closeButtonLabel: string;
  @Input() public submitButtonLabel: string;

  @Output() public readonly closed = new EventEmitter<void>();
  @Output() public readonly submitted = new EventEmitter<void>();

  constructor(private readonly matDialog: MatLegacyDialog) {}

  public onClose(event?: CustomEvent<void>): void {
    event?.stopPropagation();
    this.closed.emit();

    if (this.closable) {
      this.matDialog.closeAll();
    }
  }

  public onSubmit(): void {
    this.submitted.emit();
  }
}
