<tpm-dialog-frame icon="ec_help_begriffe" [hasSticky]="true" [header]="'GLOSSARY_DIALOG.TITLE' | translate">
  <div sticky class="sticky-container">
    <tpm-tabs [active]="tab$ | async" [tabs]="tabs" (activeTabChange)="onTabChange($any($event))"></tpm-tabs>
    <form class="glossary-content__form-container" name="serviceGlossaryForm" [formGroup]="dialogForm">
      <div class="glossary-content__tags-checkboxes__title">{{ "GLOSSARY_DIALOG.FILTERS_TITLE" | translate }}</div>
      <tpm-checkbox-field
        class="glossary-content__tags-checkboxes__item"
        formControlName="co2Balance"
        [label]="'LAUNCHPAD.PLUGINS.co2Balance.name' | translate"
      ></tpm-checkbox-field>
    </form>
  </div>
  <div class="glossary-content">
    <ng-container *ngIf="markdownText$ | async as markdownText; else loadingRef">
      <div class="html-translate" [innerHTML]="markdownText | trustHtml"></div>
    </ng-container>
    <ng-template #loadingRef>
      <tpm-glossary-skeleton-loader></tpm-glossary-skeleton-loader>
    </ng-template>
  </div>
</tpm-dialog-frame>
