import { Directive, Input, OnDestroy, OnInit, Optional, Self } from "@angular/core";
import { AbstractControl, ControlValueAccessor, FormControl, NgControl, Validators } from "@angular/forms";
import { toString } from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Directive()
export class FormFieldControl implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() public disabled: boolean = false;
  @Input() public label: string;
  @Input() public appearance: "fill" | "outline" = "outline";
  @Input() public formControlName: string | number;
  @Input()
  public set formControl(_control: AbstractControl) {
    this.control = _control;
  }
  public control: AbstractControl = new FormControl(null);

  protected readonly destroy$ = new Subject<void>();

  constructor(@Self() @Optional() private readonly ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public ngOnInit(): void {
    if (this.ngControl !== null) {
      this.control.addValidators(this.ngControl.control.validator);
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public get required(): boolean {
    return this.ngControl?.control.hasValidator(Validators.required);
  }

  public writeValue(value: string): void {
    this.control.setValue(value);
  }

  public registerOnChange(fn: any): void {
    this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      fn(toString(value));
    });
  }

  public registerOnTouched(): void {}

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    if (disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }
}
