import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { marked } from "@enersis/gp-components/gp-markdown";
import { BehaviorSubject, combineLatest } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";
import { AnalyticsService } from "src/app/core/services/analytics/analytics.service";
import { GpCmsService } from "src/app/core/services/gp-cms.service";
import { TabConfig } from "src/app/shared/ui/tabs/tabs.component";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-services-glossary-dialog",
  templateUrl: "./services-glossary.component.html",
  styleUrls: ["./services-glossary.component.scss", "../../glossary.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServicesGlossaryDialogComponent implements OnInit {
  public readonly tabs: Array<TabConfig> = [
    { name: "explanationOfTerms", label: "GLOSSARY_DIALOG.TABS.explanationOfTerms" },
    { name: "calculationLogic", label: "GLOSSARY_DIALOG.TABS.calculationLogic" },
    { name: "datasources", label: "GLOSSARY_DIALOG.TABS.datasources" }
  ];
  public readonly tab$ = new BehaviorSubject<string>(this.tabs[0].name);
  public readonly markdownText$ = this.gpCmsService.getGlossaries().pipe(
    switchMap((markdown) =>
      combineLatest([
        this.tab$.asObservable(),
        this.dialogForm.valueChanges.pipe(startWith(this.dialogForm.getRawValue()))
      ]).pipe(map(([tab, { co2Balance }]) => ({ tab, co2Balance, markdown })))
    ),
    map(({ tab, co2Balance, markdown }) => {
      if (!co2Balance) {
        return "";
      }
      return this.toHtml(markdown[tab]);
    })
  );

  public dialogForm: FormGroup;

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA)
    private readonly data: { tab: string },
    private readonly gpCmsService: GpCmsService,
    private readonly fb: FormBuilder,
    private readonly analytics: AnalyticsService
  ) {
    if (this.data.tab) {
      this.tab$.next(this.data.tab);
    }
  }

  public ngOnInit(): void {
    this.dialogForm = this.fb.group({
      co2Balance: true
    });
  }

  public onTabChange(tab: string): void {
    this.tab$.next(tab);
    this.analytics.trackGlossaryTabClick(tab);
  }

  public onTabInit(tab: string): void {
    this.analytics.trackGlossaryTabClick(tab);
  }

  private toHtml(text: string): string {
    const html = marked(text);
    const parsedDoc = new DOMParser().parseFromString(html, "text/html");

    // replace h3 with div
    parsedDoc.querySelectorAll("h3[id]").forEach((element) => {
      const div = parsedDoc.createElement("div");
      div.classList.add("headline-4");

      while (element.firstChild) {
        div.appendChild(element.firstChild);
      }
      element.parentNode.replaceChild(div, element);
    });

    return parsedDoc.body.innerHTML;
  }
}
