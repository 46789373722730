<gp-segmented-button
  [fullWidth]="fullWidth"
  [checkMark]="checkMark"
  [density]="density"
  [active]="active"
  [disabled]="disabled"
  (selected)="onSelect($any($event))"
  (disableSelected)="onDisableSelect($any($event))"
>
  <gp-segment
    *ngFor="let segment of segments; trackBy: trackByName"
    [name]="segment.name"
    [disabled]="segment.disabled"
  >
    <tpm-icon *ngIf="segment.icon" slot="icon" [icon]="segment.icon"></tpm-icon>
    {{ segment.label | translate }}
  </gp-segment>
</gp-segmented-button>
