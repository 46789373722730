<tpm-dialog-frame
  [hasCloseButton]="false"
  [closable]="false"
  [closeButtonLabel]="'DELETE_DIALOG.CANCEL' | translate"
  [submitButtonLabel]="'DELETE_DIALOG.SUBMIT' | translate"
  (closed)="onClose()"
  (submitted)="onSubmit()"
>
  <div class="content-container">
    <p class="body-2 content" [innerHTML]="data?.content ?? '...' | translate"></p>
    <tpm-icon icon="ec_warning"></tpm-icon>
  </div>
  <ng-content></ng-content>
</tpm-dialog-frame>
