<tpm-dialog-frame
  icon="ec_info"
  [formGroup]="form"
  [header]="'POLICY_DIALOG.header' | translate"
  [hasCloseButton]="data.closable"
  [hasSticky]="true"
  [closable]="false"
  [closeButtonLabel]="closeButtonLabel$ | async | translate"
  [submitButtonLabel]="submitButtonLabel$ | async | translate"
  [submitDisabled]="submitDisabled$ | async"
  (closed)="onClose()"
  (submitted)="onSubmit()"
>
  <div sticky class="sticky-container">
    <tpm-tabs [active]="tab$ | async" [tabs]="tabs" (activeTabChange)="onTabChange($any($event))"></tpm-tabs>
  </div>
  <div class="tab-container" [ngSwitch]="tab$ | async">
    <ng-container *ngSwitchCase="'terms'">
      <div class="html-translate" [innerHTML]="'POLICY_DIALOG.terms.content' | translate"></div>
    </ng-container>
    <ng-container *ngSwitchCase="'dataProtection'">
      <div class="html-translate" [innerHTML]="'POLICY_DIALOG.dataProtection.content' | translate"></div>
    </ng-container>
    <ng-container *ngSwitchCase="'cookies'">
      <div class="html-translate" [innerHTML]="'POLICY_DIALOG.cookies.content' | translate"></div>
      <div class="cookies-container" formGroupName="cookiesDetails">
        <tpm-card *ngFor="let cookie of cookies" variant="elevation-basic-10">
          <tpm-checkbox density="small" [formControlName]="cookie.name"></tpm-checkbox>
          <div class="html-translate" [innerHTML]="cookie.label | translate"></div>
        </tpm-card>
      </div>
    </ng-container>
  </div>
  <div footer class="footer-container">
    <ng-container *ngIf="warning$ | async; else checkboxes">
      <p class="body-2 warning">{{ "POLICY_DIALOG.warning" | translate }}</p>
    </ng-container>
    <ng-template #checkboxes>
      <tpm-checkbox
        density="small"
        [label]="'POLICY_DIALOG.terms.checkboxLabel' | translate"
        formControlName="termsAgreements"
      ></tpm-checkbox>
      <ng-container *ngIf="cookiesLabels$ | async as labels">
        <tpm-checkbox
          density="small"
          [label]="labels[0] | translate"
          formControlName="cookiesAgreements"
        ></tpm-checkbox>
        <p class="body-2 link" (click)="redirectToCookies()">{{ labels[1] | translate }}</p>
      </ng-container>
    </ng-template>
  </div>
</tpm-dialog-frame>
