import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map, startWith } from "rxjs/operators";
import { PLUGIN } from "src/app/common/enums/plugins";
import { SLOT } from "src/app/common/enums/slot";
import { LoadingResponse } from "src/app/common/interfaces/loading-response";
import { MODULES_CRM_IDS_MAP } from "src/app/core/constants/modules-crm-ids-map";
import { ModuleConfigLogsItem } from "src/app/core/interfaces/project-entity";
import { FilesService } from "src/app/core/services/files.service";
import { LogType, ProjectConfigurationService } from "src/app/core/services/project-configuration.service";

export type LogModules = SLOT | PLUGIN | "overall";

const LOG_FOLDER_MAP = { ...MODULES_CRM_IDS_MAP, overall: "overall" };

@Injectable()
export class ConfigurationAreaDownloadsService {
  constructor(
    private readonly projectConfigService: ProjectConfigurationService,
    private readonly filesService: FilesService
  ) {}

  public getLogOrReportList(
    module: LogModules,
    logType: LogType
  ): Observable<LoadingResponse<Array<ModuleConfigLogsItem>>> {
    return this.projectConfigService.getLogsOrReports(LOG_FOLDER_MAP[module], logType).pipe(
      map((result) => ({ result, loading: false })),
      startWith({
        result: [],
        loading: true
      }),
      catchError(() => of({ result: [], loading: false }))
    );
  }

  public downloadLogFile(module: SLOT, fileName: string, logType: "logs" | "reports"): void {
    if (fileName.endsWith("xlsx")) {
      this.filesService.postXls(`${this.projectConfigService.api}/${logType}/${LOG_FOLDER_MAP[module]}`, {
        logFiles: [fileName]
      });
    } else {
      this.filesService.postCsv(`${this.projectConfigService.api}/${logType}/${LOG_FOLDER_MAP[module]}`, {
        logFiles: [fileName]
      });
    }
  }

  public downloadArchive(module: SLOT, logFiles: Array<string>, logType: "logs" | "reports"): void {
    this.filesService.postArchive(`${this.projectConfigService.api}/${logType}/${LOG_FOLDER_MAP[module]}`, {
      logFiles
    });
  }
}
