import { Segment } from "src/app/common/components/segmented-button/segmented-button.component";

export const LAUNCHPAD_SMALL_WIDTH = "(width <= 630px)";
export const LAUNCHPAD_MEDIUM_WIDTH = "(width <= 885px)";

export const SEGMENTS: Array<Segment> = [
  {
    name: "regulated",
    label: "Netzbetrieb"
  },
  {
    name: "marketplace",
    label: "Marketplace",
    disabled: true
  }
];
