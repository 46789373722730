import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { TranslateModule } from "@ngx-translate/core";
import { DialogFrameModule } from "src/app/common/components/dialog-frame/dialog-frame.module";
import { IconModule } from "src/app/common/components/icon/icon.module";

@Component({
  selector: "tpm-delete-dialog",
  templateUrl: "delete-dialog.component.html",
  styleUrls: ["delete-dialog.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, DialogFrameModule, IconModule]
})
export class DeleteDialogComponent {
  private readonly dialogRef = inject(MatLegacyDialogRef<DeleteDialogComponent>);

  readonly data: { content: string } = inject(MAT_LEGACY_DIALOG_DATA);

  onClose(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    this.dialogRef.close(true);
  }
}
