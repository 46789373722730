import { NgFor, NgIf } from "@angular/common";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TrackByFunction
} from "@angular/core";
import "@enersis/gp-components/gp-segmented-button";
import { TranslateModule } from "@ngx-translate/core";
import { IconModule } from "../icon/icon.module";

export interface Segment {
  name: string;
  label: string;
  disabled?: boolean;
  icon?: string;
}

@Component({
  selector: "tpm-segmented-button",
  templateUrl: "segmented-button.component.html",
  styleUrls: ["./segmented-button.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgFor, TranslateModule, IconModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SegmentedButtonComponent {
  @Output() readonly selected = new EventEmitter<string>();
  @Output() readonly disableSelected = new EventEmitter<string>();

  @Input() active: string | undefined;
  @Input() fullWidth: boolean | undefined;
  @Input() disabled: boolean | undefined;
  @Input() checkMark: boolean | undefined;
  @Input() density: 0 | -1 | -2 | -3 = 0;
  @Input() segments: Array<Segment> = [];

  readonly trackByName: TrackByFunction<Segment> = (_, segment) => segment.name;

  onSelect(event: CustomEvent): void {
    event.stopPropagation();
    this.selected.emit(event.detail.name);
  }

  onDisableSelect(event: CustomEvent): void {
    event.stopPropagation();
    this.disableSelected.emit(event.detail.name);
  }
}
